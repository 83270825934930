import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const BasicVideo = makeShortcode("BasicVideo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "your-response-matters"
    }}>{`Your response matters`}</h1>
    <p>{`When your teenager expresses strong negative emotions in their words or actions, your response can make a huge difference. Let’s look at Monica’s story:`}</p>
    <p>{`Monica has had a tough day because she found out that one of her classmates has been texting some nasty and hurtful messages about her to other kids in her grade. On her way home from school, she receives a text from her dad telling her that she can't go over to her best friend Tahlia's house today because her Aunty Sue is coming over to visit. In her anger, Monica storms into the kitchen when she arrives home.`}</p>
    <h3 {...{
      "id": "watch-how-the-father-daughter-interaction-can-go-wrong-very-quickly"
    }}>{`Watch how the father-daughter interaction can go wrong very quickly…`}</h3>
    <BasicVideo url="https://player.vimeo.com/video/575138331?h=27f2868efe&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" mdxType="BasicVideo" />
    <h3 {...{
      "id": "now-see-how-a-response-with-identify-validate-and-understand-can-lead-to-a-very-different-outcome"
    }}>{`Now see how a response with ‘identify, validate and understand’ can lead to a very different outcome:`}</h3>
    <BasicVideo url="https://player.vimeo.com/video/575138246" mdxType="BasicVideo" />
    <p>{`At this point, Monica tells her dad what's been upsetting her that day. She was really hoping to talk to her best friend about it after school.`}</p>
    <p>{`Because Dad managed to hold back from reacting to Monica's outburst, and instead took the time to find out what was behind it, he now knows about the cyber-bullying Monica is experiencing and can support her better. In addition, Monica feels her dad understands her situation and this has strengthened their relationship. Monica is also learning ways to express herself and manage her emotions better.`}</p>
    <p>{`Once things had calmed down, Dad had a chat with Monica about how she can better express her strong emotions in the future, and Dad encouraged Monica to talk openly with him whenever she was struggling with any difficult issues.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      